import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import MobilePage from "../views/mobileLogin.vue";
import MobileErrorLogin from "../views/mobileErrorLogin.vue";

const routes = [
    {
        path: "/mobileLogin",
        name: "mobileHome",
        component: MobilePage,
    },
    {
        path: "/mobileErrorLogin",
        name: "mobileErrorLogin",
        component: MobileErrorLogin,
    },
    {
        path: "/guestUserSignUp",
        name: "guestUserSignUp",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/views/guestUserSignUp.vue"
            ),
    },
    {
        path: "/:category?/raffle/:id/:slug?/",
        name: "App",
        component: () => import("@/views/RaffleDetails.vue"),
    },

    {
        path: "/signIn/",
        name: "mobileSignIn",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/signInAndSignupNew/mobSignIn.vue"
            ),
    },
    {
        path: "/signUp/",
        name: "mobileSignUp",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/signInAndSignupNew/mobSignUp.vue"
            ),
    },
    {
        path: "/moreDetails",
        name: "moreDetails",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/moreDetails.vue"
            ),
    },
    {
        path: "/verifyAccount",
        name: "verifyAccount",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/verifyAccount.vue"
            ),
    },
    {
        path: "/skipVerification",
        name: "skipVerification",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/skipVerification.vue"
            ),
    },
    {
        path: "/successVerification",
        name: "successVerification",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/successVerification.vue"
            ),
    },

    {
        path: "/activatedBonus",
        name: "activatedBonus",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/activatedBonus.vue"
            ),
    },

    {
        path: "/forgotPassword",
        name: "forgotPassword",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/forgotPassword.vue"
            ),
    },

    {
        path: "/forgotPassEmailLink",
        name: "forgotPassEmailLink",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/forgotPassEmailLink.vue"
            ),
    },

    {
        path: "/resetPassword",
        name: "resetPassword",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/mobileSignUp/resetPassword.vue"
            ),
    },

    {
        path: "/:category?/raffle/:id/:slug?/",
        name: "AppWithLogin",
        component: () => import("@/views/RaffleDetailsWithLogin.vue"),
    },

    {
        path: "/claimSummary",
        name: "claimSummary",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/components/WithLoginComponents/PointsStore/claimSummary.vue"
            ),
    },

    {
        path: "/charities",
        name: "charityWithoutLogin",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/components/CharityPage/charityWithoutLogin.vue"
            ),
    },
    {
        path: "/charities",
        name: "charityWithLogin",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/components/WithLoginComponents/Charity/charityWithLogin.vue"
            ),
    },

    {
        path: "/myRaffles",
        name: "myRaffles",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/components/WithLoginComponents/MyRaffles/myRaffles.vue"
            ),
    },

    {
        path: "/userError",
        name: "userError",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/components/LoginSignupPage/errorMessage.vue"
            ),
    },

    {
        path: "/:pathMatch(.*)*",
        name: "pageNotFound",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/components/StaticPages/pageNotFound.vue"
            ),
    },

    {
        path: "/trueLayerFailed",
        name: "trueLayerFailed",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/components/WithLoginComponents/prizeclaimSystem/trueLayerFailurePage.vue"
            ),
    },

    {
        path: "/winners/",
        name: "winnersWithoutLogin",
        component: () =>
            import("@/components/WinnerswithoutLogin/winnersPageNew.vue"),
    },

    {
        path: "/winners/",
        name: "winnersWithLogin",
        component: () =>
            import(
                "../components/WithLoginComponents/Winners/winnersPageNew.vue"
            ),
    },
    {
        path: "/drawDetails/:drawCode",
        name: "drawDetailsWithLogin",
        component: () =>
            import("../components/WithLoginComponents/Winners/drawDetails.vue"),
    },

    {
        path: "/drawDetails/:drawCode/",
        name: "drawDetailsWithoutLogin",
        component: () =>
            import("../components/WinnerGallery/drawDetailsWithoutLogin.vue"),
    },

    {
        path: "/cart",
        name: "cartWithLogin",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/cart/cart.vue"
            ),
    },
    {
        path: "/dnaPayments",
        name: "dnaPaymentsWithLogin",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/cart/dnaPaymentsWithLogin.vue"
            ),
    },
    {
        path: "/dnaPayments",
        name: "dnaPaymentsWithoutLogin",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/cartWithoutLogin/dnaPaymentsWithoutLoginNew.vue"
            ),
    },
    {
        path: "/mblDnaPaymentsWithLogin",
        name: "mblDnaPaymentsWithLogin",
        component: () =>
            import(
                "../components/WithLoginComponents/cart/mblDnaPaymentsWithLogin.vue"
            ),
    },

    {
        path: "/mblDnaPaymentsWithoutLogin",
        name: "mblDnaPaymentsWithoutLogin",
        component: () =>
            import(
                "../components/cartWithoutLogin/mblDnaPaymentsWithoutLogin.vue"
            ),
    },

    {
        path: "/mblDnaModalWithLogin",
        name: "mblDnaModalWithLogin",
        component: () =>
            import(
                "../components/WithLoginComponents/cart/mblDnaModalWithLogin.vue"
            ),
    },

    {
        path: "/mblDnaModalWithoutLogin",
        name: "mblDnaModalWithoutLogin",
        component: () =>
            import(
                "../components/cartWithoutLogin/mblDnaModalWithoutLogin.vue"
            ),
    },

    {
        path: "/paymentSuccess",
        name: "paymentSuccesswithLogin",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/cart/paymentSuccess.vue"
            ),
    },

    {
        path: "/paymentFailure",
        name: "paymentFailurewithLogin",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/cart/paymentFailure.vue"
            ),
    },
    {
        path: "/paymentPending",
        name: "paymentPendingwithLogin",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/cart/paymentPending.vue"
            ),
    },
    {
        path: "/cart",
        name: "cartWithoutLogin",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/cartWithoutLogin/cartWithoutLogin.vue"
            ),
    },
    {
        path: "/paymentSuccess",
        name: "paymentSuccesswithoutLogin",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/cartWithoutLogin/paymentSuccesswithoutLogin.vue"
            ),
    },
    {
        path: "/paymentFailure",
        name: "paymentFailurewithoutLogin",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/cartWithoutLogin/paymentFailurewithoutLogin.vue"
            ),
    },
    {
        path: "/paymentPending",
        name: "paymentPendingwithoutLogin",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/cartWithoutLogin/paymentPendingwithoutLogin.vue"
            ),
    },
    {
        path: "/myCredit",
        name: "myCredit",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/My_Credit/myCredit.vue"
            ),
    },
    {
        path: "/pointsStore",
        name: "pointsStore",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/PointsStore/pointsStore.vue"
            ),
    },
    {
        path: "/pointsConfirmation",
        name: "pointsConfirmation",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "../components/WithLoginComponents/PointsStore/pointsClaimConfirmation.vue"
            ),
    },

    {
        path: "/category/:categoryData/",
        name: "categoryNew",
        component: () => import("../components/categories/newCategoryPage.vue"),
        props: true,
    },
    {
        path: "/category/:categoryData/",
        name: "categoryNewWithLogin",
        component: () =>
            import(
                "../components/WithLoginComponents/Category/newCategoryPageWithLogin.vue"
            ),
        props: true,
    },

    {
        path: "/winners_gallery/",
        name: "winnerGalleryWithoutLogin",
        component: () =>
            import("../components/WinnerGallery/winnerGallery.vue"),
    },
    {
        path: "/winners_gallery/",
        name: "winnerGalleryWithLogin",
        component: () =>
            import(
                "../components/WithLoginComponents/WinnerGallery/winnerGalleryLogin.vue"
            ),
    },

    {
        path: "/user/banks",
        name: "truelayerData",
        component: () =>
            import(
                "../components/WithLoginComponents/prizeclaimSystem/prizeclaimTruelayerData.vue"
            ),
    },
    {
        path: "/prizeclaimSystem",
        name: "prizeclaimSystem",
        component: () =>
            import(
                "../components/WithLoginComponents/prizeclaimSystem/prizeclaimSystem.vue"
            ),
    },

    {
        path: "/prizeCashClaimsWithLoginSummary",
        name: "prizeCashClaimsWithLoginSummary",
        component: () =>
            import(
                "../components/WithLoginComponents/prizeclaimSystem/prizeCashClaimsWithLoginSummary.vue"
            ),
    },

    {
        path: "/prizeConfirmationNew",
        name: "prizeConfirmationNew",
        component: () =>
            import(
                "../components/WithLoginComponents/prizeclaimSystem/prizeclaimSystemConfirmation.vue"
            ),
    },

    {
        path: "/prizeclaimSystemSummary",
        name: "prizeclaimSystemSummary",
        component: () =>
            import(
                "../components/WithLoginComponents/prizeclaimSystem/prizeclaimSystemSummary.vue"
            ),
    },

    {
        path: "/privacy/",
        name: "privacy",
        component: () => import("../components/StaticPages/privacyPolicy.vue"),
    },

    {
        path: "/tcs/",
        name: "tcs",
        component: () =>
            import("../components/StaticPages/termsAndConditions.vue"),
    },

    {
        path: "/play/",
        name: "play",
        component: () =>
            import("../components/StaticPages/responsiblePlay.vue"),
    },

    {
        path: "/help/",
        name: "help",
        component: () => import("../components/StaticPages/helpAndSupport.vue"),
    },
    {
        path: "/newslist/",
        name: "newslist",
        component: () => import("../components/StaticPages/newsAndBlogs.vue"),
    },
    {
        path: "/news/:slug/",
        name: "newsAndBlogsExtensionPage",
        component: () =>
            import("../components/StaticPages/newsAndBlogsExtensionPage.vue"),
        props: true,
    },

    {
        path: "/workWithUs",
        name: "workWithUs",
        component: () => import("../components/StaticPages/workWithUs.vue"),
    },
    // {
    //   path: "/referralPage",
    //   name: "referralPage",
    //   component: () =>
    //     import("../components/WithLoginComponents/refferalPage/referralPage.vue"),
    // },

    // {
    //   path: "/refferalLandingPage/:rafCode/",
    //   name: "refferalLandingPage",
    //   component: () =>
    //     import(
    //       "../components/WithLoginComponents/refferalPage/refferalLandingPage.vue"
    //     ),
    // },

    {
        path: "/accountsPage",
        name: "accountsPage",
        component: () => import("../views/accountDetails.vue"),
    },

    {
        path: "/accountPersonalInfo",
        name: "accountPersonalInfo",
        component: () =>
            import(
                "../components/WithLoginComponents/accountPage/accountMobilePages/accountPersonalInfo.vue"
            ),
    },

    {
        path: "/accountPayout",
        name: "accountPayout",
        component: () =>
            import(
                "../components/WithLoginComponents/accountPage/accountMobilePages/accountPayout.vue"
            ),
    },

    {
        path: "/accountMarketing",
        name: "accountMarketing",
        component: () =>
            import(
                "../components/WithLoginComponents/accountPage/accountMobilePages/accountMarketing.vue"
            ),
    },

    {
        path: "/accountChangePassword",
        name: "accountChangePassword",
        component: () =>
            import(
                "../components/WithLoginComponents/accountPage/accountMobilePages/accountChangePassword.vue"
            ),
    },
    {
        path: "/SignUpOffers",
        name: "newSignUpOffers",
        component: () =>
            import("../components/newSignUpBonus/newSignupOffers.vue"),
    },
    {
        path: "/newVerificationPopups",
        name: "newVerificationPopups",
        component: () =>
            import("../components/newSignUpBonus/newVerificationPopups.vue"),
    },
    {
        path: "/productFeeds",
        name: "productFeeds",
        component: () => import("../components/productFeeds/productFeeds.vue"),
    },
    {
        path: "/trueLayerIncomplete",
        name: "trueLayerIncomplete",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/components/WithLoginComponents/prizeclaimSystem/incompleteTruelayer.vue"
            ),
    },
    {
        path: "/trueLayercomplete",
        name: "trueLayercomplete",
        component: () =>
            import(
                /* webpackChunkName: "about" */ "@/components/WithLoginComponents/prizeclaimSystem/completeTruelayer.vue"
            ),
    },
    {
        path: "/androidRoute",
        name: "androidRoute",
        component: () => import("@/components/vueMobileApp/androidRoute"),
    },
    {
        path: "/iosRoute",
        name: "iosRoute",
        component: () => import("@/components/vueMobileApp/iosRoute"),
    },
    {
        path: "/",
        name: "home",
        component: HomePage,
    },

    {
        path: "/",
        name: "homeLogin",
        component: () => import("@/views/homeLogin.vue"),
    },

    {
        path: "/complaints/",
        name: "complaints",
        component: () => import("../components/StaticPages/complaintsPage.vue"),
    },

    {
        path: "/complaints/",
        name: "complaints",
        component: () => import("../components/StaticPages/complaintsPage.vue"),
    },
    {
        path: "/sitemap.xml",
        name: "sitemap-xml",
        beforeEnter: (to, from, next) => {
            // Redirect to the static XML file
            window.location.href = '/sitemap.xml';
            next(false);
        }
    },
    {
        path: "/sitemap",
        name: "sitemap-html",
        component: () => import("../components/StaticPages/sitemap.vue")
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
