import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import Vue from 'vue';
import platformMixin from './mixins/platformMixin';
import VueGtag from "vue-gtag";

const gtmIds = {
  production: "G-9SD0QZYW0H",
  development: "G-C4NZH97C4E"
}
const prodTrackingEnabled = currentPath.includes("https://raffolux.com") ||
  currentPath.includes("https://www.raffolux.com");
const gtmId = gtmIds[prodTrackingEnabled ? "production" : "development"];

loadFonts()
const app = createApp(App)
app.mixin(platformMixin);
app.use(router)
  .use(VueGtag, {
    pageTrackerTemplate(to, from) {
      return {
        page_referrer: from.path,
        page_location: to.path,
      }
    },
    config: {
      id: gtmId,
      pageTrackerScreenviewEnabled: true,
    }
  }, router)
  .use(store)
  .use(vuetify)
  .mount('#app');
